import axios from '@/http/axios'

export function getRefundRulesList (page, filters = [], sorts = []) {
  let queries = ''
  if (page > 1) {
    queries += `page=${page}`
  }

  if (filters.length) {
    if (queries.length > 0) queries += '&'

    queries += filters.join('&')
  }

  if (sorts.length) {
    if (queries.length > 0) queries += '&'

    queries += sorts.join('&')
  }

  if (queries.length > 0) queries = `?${  queries}`

  return axios({
    url: `v1/admin/refund-rules${queries}`,
    method: 'get'
  })
}


export function getRefund (id) {
  return axios({
    url: `v1/admin/refund-rules/${id}`,
    method: 'get'
  })
}

export function editRefund (id, payment) {
  return axios.patch(`v1/admin/refund-rules/${id}`, payment)
}
