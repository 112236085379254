<template>
  <div class="refunds-list md:w-1/2 mx-auto fixed-draggable-dynamic-table-wrapper-height">
    <profile-main-box :actions-list="actionsList"
                      @invoice="handleRowClicked(data[0])"
                      @addon="handleRowClicked(data[1])"
                      @coach="handleRowClicked(data[2])"
    />


    <!-- edit sale addon prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editSaleAddonPromptStatus"
      @close="editSaleAddonPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('editRefundBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('refunds.edit.dynamicTitle', {name: selectedRow.type}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editSaleAddonPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <edit-refund :refund-id="selectedRow.id" :in-modal="true" @edited="handleReloadList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show sale addon details prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showSaleAddonPromptStatus"
      @close="showSaleAddonPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="checkUserPermissions('sale_addon.update')" @click="editSaleAddonPromptStatus = true">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ selectedRow.type }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showSaleAddonPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <refunds-profile :refund-id="selectedRow.id"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="insertSaleAddon" v-show="false" @click="editSaleAddonPromptStatus = true"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {getRefundRulesList} from "../../../../http/requests/refundRules";
  import {getSaleAddons} from "../../../../http/requests/salesAddons";
  import {addComma, checkUserPermissions} from "../../../../assets/js/functions";
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import RefundsProfile from "../profile/refundsProfile";
  import EditRefund from "../edit/editRefund";
  import ProfileMainBox from "../../../../components/profileMainBox/profileMainBox2";

  export default {
    name: "refundsList",
    metaInfo () {
      return {
        title: this.$t('refunds.list.title')
      }
    },
    components: {ProfileMainBox, EditRefund, RefundsProfile, CustomIcon},
    data() {
      return {
        showSaleAddonPromptStatus: false,
        editSaleAddonPromptStatus: false,
        selectedRow: {},
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        requestSent: false,
        options: {
          id: 'refundsList',
          rowKeyField: 'id'
        },
        actionsList: [],
        refundTypes: {
          1: {
            name: this.$t('refunds.list.actions.invoice'),
            event: 'invoice',
            icon: 'INVOICE'
          },
          2: {
            name: this.$t('refunds.list.actions.saleAddon'),
            event: 'addon',
            icon: 'PLUS'
          },
          3: {
            name: this.$t('refunds.list.actions.coach'),
            event: 'coach',
            icon: 'USER'
          }
        },
        actions: [
          {
            toolbar: [
              // {
              //     id: 'insertSaleAddon',
              //     icon: 'PLUS',
              //     iconPack: 'useral',
              //     color: 'success',
              //     permission: 'sale_addon.create'
              // }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                icon: 'icon-printer',
                iconPack: 'feather'
              },
              {
                id: 'downloadTable',
                icon: 'icon-download',
                iconPack: 'feather'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created() {
      setTimeout(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', ' md:w-1/2 mx-auto')
      }, 50)

      this.getRefundRules()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getRefundRules() {
        if (!this.requestSent) {
          this.requestSent = true
          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (this.data.length < this.total_count || !this.total_count) {
              if (this.$refs.refundsList && this.data.length === 0) this.$refs.refundsList.loadMoreStatus = 'FirstLoad'
              else if (this.$refs.refundsList && this.data.length > 0) this.$refs.refundsList.loadMoreStatus = 'Loading'

              getRefundRulesList(this.page).then((response) => {
                const refundsList = response.data
                this.actionsList = []

                refundsList.data.forEach((refund) => {

                  this.data.push({
                    id: refund.id,
                    rowNumber: this.data.length + 1,
                    type: this.refundTypes[refund.type].name,
                    count: refund.schedules.length
                  })

                  this.actionsList.push({
                    event: this.refundTypes[refund.type].event,
                    header: this.refundTypes[refund.type].name,
                    i18n: '',
                    icon: this.refundTypes[refund.type].icon,
                    iconPack: 'useral',
                    colorCode: '#000000'
                  })
                })

                this.total_count = refundsList.pagination.total
                this.page = refundsList.pagination.current_page + 1


                if (this.$refs.refundsList) this.$refs.refundsList.loadMoreStatus = ''
                this.requestSent = false
              }).catch((error) => {
                if (axios.isCancel(error)) {
                  this.$vs.notify({
                    title: this.$t('alert.duplicateRequest.title'),
                    text: this.$t('alert.duplicateRequest.message'),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400,
                    color: 'warning'
                  })
                } else if (this.$refs.refundsList) this.$refs.refundsList.loadMoreStatus = 'Danger'
                this.requestSent = false
              })
            } else {
              this.requestSent = false
            }
          }, 400)
        }
      },
      handleRowClicked(row) {
        this.selectedRow = row
        this.showSaleAddonPromptStatus = true
      },
      handleReloadList() {
        this.data = []
        this.page = 1
        this.total_count = 0
        this.showSaleAddonPromptStatus = false
        this.editSaleAddonPromptStatus = false
        this.getRefundRules()
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style scoped>
  .refunds-list {
    height: calc(100vh - 150px);
    background-color: #ffffff;
    border: 1px solid #cdcdcd;
    border-top: 0;
    border-radius: 0 0 0.5rem 0.5rem;
  }
</style>
